/**
 * Tilled Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MiddeskBusinessOrder { 
    /**
     * The product/package to be ordered. Currently, only `documents`.
     */
    product: MiddeskBusinessOrder.ProductEnum;
    /**
     * Subproducts to be ordered with the product (if applicable).
     */
    subproducts?: Array<MiddeskBusinessOrder.SubproductsEnum>;
}
export namespace MiddeskBusinessOrder {
    export type ProductEnum = 'documents';
    export const ProductEnum = {
        DOCUMENTS: 'documents' as ProductEnum
    };
    export type SubproductsEnum = 'Articles of Incorporation';
    export const SubproductsEnum = {
        ARTICLES_OF_INCORPORATION: 'Articles of Incorporation' as SubproductsEnum
    };
}


