import { AdminExtendedAccount } from '@admin-api/index';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MiddeskBusiness } from '../../../../../projects/tilled-admin-api/src/model/middeskBusiness';
import { MiddeskDocument } from '../../../../../projects/tilled-admin-api/src/model/middeskDocument';

@Component({
  selector: 'app-middesk-documents-retrieval-dialog',
  templateUrl: './middesk-documents-retrieval-dialog.component.html',
  styleUrls: ['./middesk-documents-retrieval-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MiddeskDocumentsRetrievalDialogComponent {
  public account: AdminExtendedAccount;
  public middeskBusiness: MiddeskBusiness;
  public middeskDocuments: MiddeskDocument[];

  constructor(
    public matDialogRef: MatDialogRef<MiddeskDocumentsRetrievalDialogComponent>,
  ) {
  }
}
