<div class="dialog-content-wrapper space-y-2 flex flex-col">
  <mat-toolbar
    matDialogTitle
    class="mat-primary m-0 bg-transparent p-0 h-fit flex space-x-4"
  >
    <span class="dialog-title whitespace-pre-line"
      >Retrieve Articles of Incorporation from Middesk</span
    >
    <mat-icon
      style="cursor: pointer; color: #1b253b !important"
      (click)="matDialogRef.close('cancel')"
      aria-label="Close dialog"
      svgIcon="mat_outline:close"
      class="close-icon"
    ></mat-icon>
  </mat-toolbar>

  <div matDialogContent class="self-center mx-auto pl-0 pr-8">
    Submit this form to pull this Merchant's Articles of Incorporation from
    Middesk.
  </div>

  <div MatDialogActions class="pt-4 mr-6">
    <div class="btn-group flex space-x-4 flex-row flex-nowrap justify-evenly">
      <button
        mat-stroked-button
        class="cancel-button flex-1"
        (click)="matDialogRef.close('cancel')"
        aria-label="Cancel"
        *ngIf="true"
      >
        Cancel
      </button>
      <button
        mat-raised-button
        class="save-button flex-1 bg-primary text-white"
        (click)="matDialogRef.close('retrieveAOI')"
        [disabled]="false"
        aria-label="Retrieve AOI"
        *ngIf="true"
      >
        Retrieve AOI
      </button>
    </div>
  </div>
</div>
