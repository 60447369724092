/**
 * Tilled Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MiddeskDocument { 
    /**
     * Content type of the file.
     */
    content_type: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Type of the document.
     */
    document_type: MiddeskDocument.DocumentTypeEnum;
    /**
     * URL to download the file.
     */
    download_url: string;
    /**
     * Name of the file.
     */
    filename: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Size of the file in bytes.
     */
    size: number;
}
export namespace MiddeskDocument {
    export type DocumentTypeEnum = 'Initial_filing' | 'Registration';
    export const DocumentTypeEnum = {
        INITIAL_FILING: 'Initial_filing' as DocumentTypeEnum,
        REGISTRATION: 'Registration' as DocumentTypeEnum
    };
}


